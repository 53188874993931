footer {
    display: block;
    width: 100%;
    position: relative;

    &.show_as_popup {
        position: absolute;
        //bottom: -174px;
        bottom: 0;
        z-index: 30001;

        .footer_company {
            display: none;
        }

        .footer_privacy {
            position: fixed;
            bottom: 0;

            &.hidden {
                bottom: -174px;
            }
        }
    }

    .footer_company {
        height: 265px;
        display: flex;
        width: 100%;
        justify-content: center;
        background-color: #222222;
        flex-direction: column;
        align-items: center;

        h3 {}

        img {
            height: 57px;
            align-self: center;

            &.linked_in {
                height: 42px;
                margin: 10px 0px 4px 0px;
            }
        }

        p {
            width: auto;
            color: white;
            font-size: 15px;
            font-weight: 500;
            margin: 0px 0px 8px 0px;
            color: #cccccc;

            a {
                color: #56bbb1;
                text-decoration: none;
            }
        }

        p:last-of-type {
            margin: 0;
        }
    }

    .footer_privacy {
        height: 223px;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        bottom: 0;
        background: #000000;

        p {
            max-width: 650px;
            color: white;
            font-size: 12px;
            line-height: 23px;
            font-weight: 500;
            margin: 0px;
            padding: 0px 0px 12px 0px;
            align-self: center;
            text-align: center;

            a {
                color: #56bbb1;
                text-decoration: none;
            }
        }

        p:last-of-type {
            padding: 0;
        }

        .fa-times {
            width: 16px;
            color: white;
            position: absolute;
            top: 15px;
            right: 25px;
            padding: 0;
            background: 0;
            border: 0;
        }
    }
}