@import "colors";

body {
  margin: 0;
  font-family: "nimbus", "poppins", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100vw;
  height: 100vh;
  max-width: 100%;

  &.no_scroll {
    overflow-y: hidden;
  }
}

div {
  position: relative;
}

#root {
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

button {
  font-family: 'nimbus';
  border-radius: 3px;
  color: #fff;
  font-weight: 300;
  padding: 7px 15px;
  font-size: 18px;
  background-color: $cinq;
  border-color: $cinq;
  cursor: pointer;
  text-align: center;
  width: 200px;
  text-transform: uppercase;
  letter-spacing: 1px;

  &.little-guy {
    background-color: $mvd-blue;
    border-color: $mvd-blue;
    color: white;
    width: auto;
    font-size: 10px;
    padding: 6px 10px;
    box-shadow: none;
    border-style: solid; // this gets rid of shadow
  }

  &.blue {
    background-color: $mvd-blue;
    color: white;
    border: none;
    height: 32px;
  }
}

ul {
  li {
    list-style: none;
  }
}

p {
  width: 100%;

  &.no-margin {
    margin: 0px;
  }
}

input,
select {
  font-family: 'nimbus';
  border: solid lightgray .5px;
  border-radius: 5px;
  height: 25px;
  padding-left: 10px;
  color: #4b4741;
  min-width: 200px;
  padding: 5px 0px 0px 10px;

  &:focus,
  &:focus-visible,
  &:active {
    border: solid #4b4741 .5px !important;
    outline: none;
  }
}

.error-input {
  border: 1px solid red !important;
}

select {
  height: 35px;
}

label {
  display: block;
  width: 100%;
  margin-bottom: 5px;
}

.bump-left-20 {
  margin-left: 20px !important;
}

.bump-left-40 {
  margin-left: 40px !important;
}

.bump-left-120 {
  margin-left: 120px !important;
}

.bump-left {
  margin-left: 80px !important;
}

.bump-right {
  margin-right: 80px;
}

.bump-right-20 {
  margin-right: 20px !important;
}

.bump-right-10 {
  margin-right: 10px !important;
}

.hidden {
  display: none !important;
}

.centered {
  text-align: center;
}

.leftside {
  text-align: left;
  float: left !important;
}

.rightside {
  text-align: right;
  float: right !important;
}

.full-page {
  width: 100%;
}

.half-page {
  float: left;
  width: 46%;
  padding: 10px;
  position: relative;

  &.static {
    position: static;
  }
}

.mini-half-page {
  float: left;
  width: 40%;
  padding: 10px;
  position: relative;

  &.widget-bump {
    margin-right: 70px;
  }
}

.quarter-page {
  float: left;
  width: 24%;
  padding: 10px;
}

.three-quarter-page {
  float: left;
  width: 75%;

  &.activations {
    float: none;
    display: flex;
    justify-content: space-between;
    margin: 30px 0px 25px;
  }
}

.two-third-page,
.two-thirds-page {
  float: left;
  width: 60%;
  padding: 10px;
}

.third-page {
  float: left;
  width: 30%;
  padding: 10px;
}

.fifth-page {
  float: left;
  width: 18%;
  padding: 10px;

  &.home {
    padding: 6px;
  }
}

.sixth-page {
  float: left;
  width: 12%;
  padding: 10px;

  &.home {
    padding: 6px;
  }
}

.top-bump,
.top-bump-10 {
  margin-top: 10px;
}

.top-bump-20 {
  margin-top: 20px;
}

.top-bump-40 {
  margin-top: 40px;
}

.top-bump-60 {
  margin-top: 60px;
}

.top-bump-100 {
  margin-top: 100px;
}

.top-bump-150 {
  margin-top: 150px;
}

.bottom-bump {
  margin-bottom: 10px;
}

.fifteen-space {
  height: 15px;
}

.top-buffer {
  margin-top: 25px;
}

.top-large-buffer {
  margin-top: 80px;
}

.left-buffer {
  margin-left: 20px;
}

.right-buffer {
  margin-right: var(--margin);
}

.full-centered-div {
  width: 1280px;
  margin: auto;
}

.error {
  border: 1px solid red;
}

.bold {
  font-weight: bold;
  font-size-adjust: 1.5;
}

.error-message {
  color: red;
  font-size: 12px;
  margin-top: -17px;
}

.disabled {
  color: #c3c3c3;
}

table {
  th {
    text-align: left;
  }
}

.mvd-blue {
  color: $mvd-blue;
}

.red {
  color: $mvd-red !important;
}

.background-red {
  background-color: $mvd-red !important;
  border-color: $mvd-red !important;
}

.blue {
  color: $blue !important;

  &:hover {
    cursor: pointer;
  }
}

.background-blue {
  background-color: $blue !important;
  border-color: $blue !important;
}

.yellow {
  color: $yellow !important;
}

.background-yellow {
  background-color: $yellow !important;
  border-color: $yellow !important;
}

.green {
  color: $green !important;
}

.background-green {
  background-color: $green !important;
  border-color: $green !important;
}

.darkgreen {
  color: darkgreen !important;
}

.background-darkgreen {
  background-color: darkgreen !important;
  border-color: darkgreen !important;
}

.styled-link {
  text-decoration: none;
}

.spacer {
  width: 25px;
  display: inline-grid;
}

.link {
  color: blue;
  text-decoration: underline;

  &.small {
    font-size: 12px;

    &.cluster {
      float: left;
      height: 50px;
    }
  }

  &:hover {
    cursor: pointer;
  }
}

.checkbox-switch {
  width: 180px;
  padding: 0px !important;
}

.switch {
  position: relative;
  display: block !important;
  vertical-align: top;
  width: 100px;
  height: 30px;
  padding: 3px;
  margin: 0 10px 10px 0;
  background: linear-gradient(to bottom, #eeeeee, #FFFFFF 25px);
  background-image: -webkit-linear-gradient(top, #eeeeee, #FFFFFF 25px);
  border-radius: 18px;
  box-shadow: inset 0 -1px white, inset 0 1px 1px rgba(0, 0, 0, 0.05);
  cursor: pointer;
  box-sizing: content-box;
}

.switch-input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  box-sizing: content-box;
  min-width: auto;
}

.switch-label {
  position: relative;
  display: block;
  height: inherit;
  font-size: 10px;
  text-transform: uppercase;
  background: #eceeef;
  border-radius: inherit;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.12), inset 0 0 2px rgba(0, 0, 0, 0.15);
  box-sizing: content-box;
}

.switch-label:before,
.switch-label:after {
  position: absolute;
  top: 50%;
  margin-top: -.5em;
  line-height: 1;
  -webkit-transition: inherit;
  -moz-transition: inherit;
  -o-transition: inherit;
  transition: inherit;
  box-sizing: content-box;
}

.switch-label:before {
  content: attr(data-off);
  right: 11px;
  color: #aaaaaa;
  text-shadow: 0 1px rgba(255, 255, 255, 0.5);
}

.switch-label:after {
  content: attr(data-on);
  left: 11px;
  color: #FFFFFF;
  text-shadow: 0 1px rgba(0, 0, 0, 0.2);
  opacity: 0;
}

.switch-input:checked~.switch-label {
  background: #E1B42B;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.15), inset 0 0 3px rgba(0, 0, 0, 0.2);
}

.switch-input:checked~.switch-label:before {
  opacity: 0;
}

.switch-input:checked~.switch-label:after {
  opacity: 1;
}

.switch-handle {
  position: absolute;
  top: 4px;
  left: 4px;
  width: 28px;
  height: 28px;
  background: linear-gradient(to bottom, #FFFFFF 40%, #f0f0f0);
  background-image: -webkit-linear-gradient(top, #FFFFFF 40%, #f0f0f0);
  border-radius: 100%;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
}

.switch-handle:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -6px 0 0 -6px;
  width: 12px;
  height: 12px;
  background: linear-gradient(to bottom, #eeeeee, #FFFFFF);
  background-image: -webkit-linear-gradient(top, #eeeeee, #FFFFFF);
  border-radius: 6px;
  box-shadow: inset 0 1px rgba(0, 0, 0, 0.02);
}

.switch-input:checked~.switch-handle {
  left: 74px;
  box-shadow: -1px 1px 5px rgba(0, 0, 0, 0.2);
}

/* Transition
========================== */
.switch-label,
.switch-handle {
  transition: All 0.3s ease;
  -webkit-transition: All 0.3s ease;
  -moz-transition: All 0.3s ease;
  -o-transition: All 0.3s ease;
}

.fa-spinner {
  animation: spin-animation 0.5s infinite;
  display: inline-block;
}

@keyframes spin-animation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(359deg);
  }
}

.filter-button {
  color: black;
  font-size: 16px;
  width: fit-content;
  padding: 5px 25px 5px 15px;
  border-radius: 12px;
  text-align: center;
  display: inline-block;
  margin-top: 10px;
  float: left;

  i {
    margin-left: 10px;
  }

  &:hover {
    cursor: pointer;
  }
}

.note {
  font-size: 11px;
}

.break-20 {
  height: 20px;
}

.break-40 {
  height: 40px;
}

.break-60 {
  height: 60px;
}

.hover-point {
  &:hover {
    cursor: pointer;
  }
}

.no-top-margin {
  margin-top: 0px !important;
}

.bumper-page {
  padding: 50px 50px;
}

.no-underline {
  text-decoration: none;
}

.unselectable {
  background-color: #ddd;
  cursor: not-allowed
}

.no-hover {
  &:hover {
    cursor: default !important;
  }
}


.hundred-width {
  width: 100px;
}

.two-hundred-width {
  width: 200px;
}

.two-fifty-width {
  width: 250px;
}

.loading {
  font-size: 40px;
  color: #305d62;

  min-height: 220px;

  &.full-screen-margin {
    margin-left: 80px;
  }

  &.no--margin {
    //margin-left: 0px;
  }

  &.adjust-left-margin {
    //margin-left: 44px;
  }
}

.loading:after {
  overflow: hidden;
  display: inline-block;
  vertical-align: bottom;
  -webkit-animation: ellipsis steps(4, end) 900ms infinite;
  animation: ellipsis steps(4, end) 900ms infinite;
  content: "\2026";
  /* ascii code for the ellipsis character */
  width: 0px;
}

@keyframes ellipsis {
  to {
    width: 1.25em;
  }
}

@-webkit-keyframes ellipsis {
  to {
    width: 1.25em;
  }
}

.no-margin {
  margin: 0px !important;
}

.no-padding {
  padding: 0px !important;
}

.no-height {
  height: auto !important;
}