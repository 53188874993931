@import "../../styles/application/colors.scss";

.newSignUp {

    .main {
        display: flex;
        justify-content: center;
        background-blend-mode: multiply;
        background-image: linear-gradient(180deg, #56bbb1 10%, #2f5d62 91%), url(https://www.cinqdi.com/wp-content/uploads/sites/66/2023/11/coming-soon-BG.jpeg) !important;
        overflow-y: auto;
    }

    .main_wrapper {
        width: 618px;
    }

    img {
        display: flex;
    }

    h2 {
        font-size: 38px;
        //color: #777c7a;
        color: white;
        text-align: center;
        height: 100px;
        margin: 120px 0px 32px 0px;
        border-bottom: 1px solid white;
    }

    h3 {
        color: white;
        //color: #1e1e1e;
        padding: 0px 0px 32px 0px;
        border-bottom: 1px #ffffff solid;
    }

    .entry_field {
        display: flex;
        width: 100%;
        margin-bottom: 35px;
        justify-content: space-between;

        &.single_entry {
            flex-direction: column;

            input {
                width: 100%;
                padding: 0px 0px 0px 12px;
                box-sizing: border-box;
            }

            .dropdown {
                label {
                    select {
                        width: 100%;
                        padding: 0px 0px 0px 12px;
                        -webkit-appearance: none;
                        appearance: none;
                        border: none;
                    }
                }

                label:after {
                    content: "";
                    position: absolute;
                    right: 15px;
                    top: 20px;
                    border-top: solid 4px #777c7a;
                    border-left: solid 4px transparent;
                    border-right: solid 4px transparent;
                    pointer-events: none;
                }
            }
        }

        .dual_entry {
            display: flex;
            flex-direction: column;
            width: calc(50% - 8px);
            margin: 0;

            input {
                padding: 0px 0px 0px 12px;
                box-sizing: border-box;
            }
        }

        label {
            //color: #777c7a;
            //color: black;
            color: white;

            &.error {
                color: #F6C250;
                font-size: 15px;
                border: 0;
                margin: 5px 0px 0px 0px;
                display: none;

                &.show {
                    display: block;
                }
            }
        }

        input,
        select {
            height: 42px;
            border: 1px solid #cec8ca;
            border-radius: 5px;
            width: 100%;
            padding: 0px;
            color: #757575;

            &:focus {
                border: 1px solid #cec8ca !important;
            }

            &.error {
                border: 1px solid #F6C250 !important;
            }

            &.value_selected {
                color: #4c4741;
            }
        }

    }

    .signup_interests {
        display: inline-block;
        //border-bottom: 1px #d0cacc solid;
        border-bottom: 1px #ffffff solid;
        padding-bottom: 18px;

        h4 {
            color: white;
            //color: #1e1e1e;
            //color: #3e4442
            margin: 21px 0px 36px 0px;
        }

        ul {
            margin: 0;
            padding: 0;

            .category-inline-list {
                display: inline-block;
            }

            li {
                width: fit-content;
                float: left;
                background: white;
                border: 0;
                border-radius: 24px;
                border: 1px solid black;
                padding: 6px 15px;
                text-transform: capitalize;
                margin: 0px 16px 12px 0px;

                &:hover {
                  cursor: pointer;
                }

                p {
                    color: black;
                    margin: 0;
                    font-size: 14px;
                    user-select: none;
                }

                i {
                    margin-left: 8px;
                }

                .fa-times {
                    margin-left: 12px;
                }

                &.selected {
                    //background: #b7aeb1;
                    background: #ccc6c8;
                }
            }
        }

        &.hidden {
            display: none;
        }
    }

    .signup_footer {
        //border-top: 1px #d0cacc solid;
        justify-content: end;
        display: flex;

        button {
            font-size: 14px;
            border: none;
            width: auto;
            height: 46px;
            margin: 32px 0px 70px 12px;
            padding: 0px 22px;
            border-radius: 5px;

            p {
                height: 12px;
                margin: 0;
            }

            &.create_account {
                background-color: #568BB1;

                &.toggled {
                    background-color: #568BB1;
                }
            }

            &.personalize {
                background-color: $mvd-mulberry;
            }

            &.cancel {
                padding: 0;
                background: 0;

                &:hover {
                    cursor: pointer;
                    color: #F6C250;
                }
            }
        }
    }

    &.hidden {
        display: none;
    }
}