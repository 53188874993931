.platform-activations {
  display: flex;
  flex-direction: column;

  ul {
    padding: 0;
    margin: 0;

    li {
      min-height: auto;
      font-size: 12px;

      &.activated {
        background-color: hsla(0, 0%, 47%, .125) !important;
      }

      &:hover {
        background-color: rgba(246, 194, 80, .125);
      }

      .activate_check_image_wrapper {
        display: flex;
        align-items: center;

        input {
          margin-right: 14px;
          margin-top: 0;
          min-width: 20px;
          width: 20px;
        }

        img {
          max-height: 40px;
          object-fit: contain;
        }

        .error {
          border: none;
          color: red;
          margin: 0px 0px 0px 15px;
        }
      }

      .activate_field_values_wrapper {
        display: flex;
        align-items: center;
        margin: 16px 0px 0px 0px;

        label,
        p {
          width: fit-content;
          font-size: 13px;
          margin: 0;
        }

        p.mandatory {
          color: #bc403f;
          margin: 10px 7px 0px 0px;
          font-size: 18px;
        }


        .field_value {
          margin-right: 20px;

          .field_value_label {
            display: flex;
            width: auto;
            align-items: center;
            height: 40px;
          }

          .field_value_input {
            display: flex;
            height: 40px;
            align-items: center;

            fieldset {
              display: flex;
              border: 0;
              padding: 0;
              margin: 0;

              p {
                margin-right: 10px;
              }

              input[type="radio"] {
                height: 15px;
                min-width: 0;
                margin: -2px 5px 0px 0px;
              }
            }
          }
        }
      }

      .activation_button_wrapper {
        display: flex;
        justify-content: end;

        button {
          border: 0;
          color: #fff;
          font-size: 18px;
          padding: 10px 24px;
          text-transform: capitalize;

          p {
            margin: 5px 0px 0px 0px;
          }
        }
      }
    }
  }

  .platform-missing {
    width: 258px;
    margin: 40px 80px;
    display: inline-block;

    button.show-missing-platform {
      background: none;
      color: #0000FF;
      padding: 0;
      text-align: left;
      font-size: 15px;
      border: 0;

      &:hover {
        text-decoration: underline;
        //color: $cinq-green;
      }

      &.collapsed {
        margin: 25px 0px;
      }
    }

    p {
      line-height: 26px;
    }

    textarea {
      height: 80px;
      width: 100%;
      padding: 15px;
      box-sizing: border-box;
      border: 0.5px solid #08beb1;
      resize: none;
      font-family: 'nimbus';
      line-height: 20px;
      border-radius: 6px;
    }

    .platform-missing-button-wrapper {
      display: flex;
      width: 100%;
      justify-content: center;

      button {
        background: #08beb1 !important;
        width: 188px;
        color: white;
        border: 0;
        font-size: 18px;
        margin: 25px 0 0;
        padding: 5px 0px;
        text-transform: capitalize;

        p {
          font-size: 16px;
          margin: 5px 0 0;
        }
      }
    }

    label {

      &.error {
        color: #864B8D;
        font-size: 15px;
        border: 0;
        margin: 5px 0px 0px 0px;
        display: none;

        &.show {
          display: block;
        }
      }
    }
  }

  p.export-confirmation-p {    
    display: flex;
    padding: 10px 0px 10px 65px;
  }

  button {
    &.export-confirmation {
      border: 0;
      background: none;
      color: blue;
      font-size: 14px;
      margin-top: 1px;
      width: fit-content;
      text-transform: capitalize;
      cursor: pointer;
      padding: 0px 0px 0px 5px; 

      &:hover {
        text-decoration: underline;
      }
    }
  }
}