.activations_table {

  table {
    width: 100%;
    margin-bottom: 80px;

    thead {
      tr {
        height: 50px;
      }
    }
    tbody {
      tr {
        height: 50px;

        &:hover {
          td {
            //color: #568bb1;
          }
        }

        td {
          color: #48494b;
          font-size: 14px;
          max-width: 100px;
          text-wrap: balance;
          line-height: 26px;

          i {
            font-size: 18px;
            cursor: pointer;

            &.fa-trash {
              padding-left: 8px;

              &:hover {
                color: red;
              };
            }
            &.fa-square-up-right {
               &:hover {
                color: #568bb1; 
              };
            }
          }

          &.hover-popup {
            color: #568bb1;

            &:hover {
              cursor: pointer;

              #box {
                display: block;
              }
            }

            #box {
              display: none;
              background-color: #568bb1;
              border-radius: 5px;
              color: #fff;
              font-size: 14px;
              margin-top: -85px;
              position: absolute;
              right: -40px;
              text-align: center;
              z-index: 1000;
              width: auto;
              padding: 0;
              margin-bottom: 0;

              li {
                border-bottom: 1px solid #fff;
                padding: 2px;

                &:hover {
                  background-color: #08beb1;

                  button {
                    background-color: #08beb1;
                  }
                }

                button {
                  font-size: 14px;
                  text-transform: none;
                  width: auto;
                  letter-spacing: normal;
                  border: 0;
                }
              }
              li:first-child {
                &:hover {
                  border-top-left-radius: 5px;
                  border-top-right-radius: 5px;
                }
              }
              li:last-child {
                &:hover {
                  border-bottom-left-radius: 5px;
                  border-bottom-right-radius: 5px;
                }
              }
            }
          }
        }
      }
    }
  }
}