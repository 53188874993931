.new-headlines {

  .headline-article-title {
    display: flex;
    height: auto;
    width: 100%;        
    justify-content: center;

    .headlines-title-wrapper {
      display: flex;
      width: 100%;
      padding: 0px 0px 50px;
      flex-direction: column;
      border-bottom: 1px solid black;

      .headlines {
        display: flex;
        align-items: center;
            
        h1 {
          width: fit-content;
          margin: 0px 12px 0px 0px;
        }

        a {
          display: contents !important;
          margin: 0px 12px 0px 0px;
        }
      }

      h1 {
        color: #333333;
        font-size: 20px;
        margin: 0px;
      }

      .headline-article-audience-display {
        display: flex;
        margin: 10px 0px 30px 2px;

        i {
          margin-right: 10px;
        }

        p {
          margin: 0;
        }
      }

      .headline-title-article-body {
        display: flex;
        margin: 0;
        align-items: center;

        img {
          height: 150px;
        }

        p {
          max-height: 150px;
          overflow-y: scroll;
          overflow: -moz-scrollbars-vertical;
          margin: 0px 0px 0px 50px;
          line-height: 24px;
        }
      }
    }
  }

  .headline-article-analytics {
    display: flex;
    float: left;
    width: 100%;
    justify-content: center;
    margin: 50px 0px;

    .headline-article-analytics-wrapper {
      display: flex;
      flex-direction: column;
      width: 100%;
      justify-content: center;

      h2 {
        text-align: left;
        font-size: 20px;
        font-weight: bold;
        margin-left: -151px;
        color: #333333;
      }

      h3 {
        text-align: center;
        font-weight: 100;
        font-size: 17px;
        text-decoration: underline;
        margin: 30px 0px;
      }

      .headline-article-analytics-widgets-wrapper {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        .audience-bars {

          .hover-point {
            margin: 20px 0px 5px 0px;
          }

          .audience-bar {

            p.percent {
              font-size: 13px;
              margin-top: 1px;
            }
          }
        }

        .widget-list {
          margin-left: 0;
          width: 400px;

          .header {
            display: flex;
            padding: 0;

            p {
              text-align: left;
              padding: 0;
            }
          }

          .widget-div {
            margin: 35px 0px;
          }
        }
      }
    }
  }
}