.login_header {
    width: 100%;
    height: 99px;
    background: #ffffff;
    display: flex;
    justify-content: center;

    .login_header_wrapper {
        display: flex;
        width: 82% !important;
        justify-content: center;
        align-self: center;
        padding-right: 32px;
        padding-left: 30px;
        justify-content: space-between;

        a {
          display: flex;

          img {
            height: 40px;
          }

            &.contact {
                background-color: #56BBB1;
                border-radius: 5px;
                color: #ffffff;
                border: none;
                font-size: 13px;
                width: auto;
                padding: 15px 20px 12px;
                font-weight: 700;
                line-height: 14px;
                text-decoration: none;
            }
        }
    }
}
