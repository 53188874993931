@font-face {
  font-family: 'poppins';
  font-style: normal;
  font-weight: normal;
  src:
    url('../../fonts/poppins-regular.woff2') format('woff2'),
    url('../../fonts/poppins-regular.woff') format('woff');
}

@font-face {
  font-family: 'poppins';
  font-style: normal;
  font-weight: 100;
  src:
    url('../../fonts/poppins-thin.woff2') format('woff2'),
    url('../../fonts/poppins-thin.woff') format('woff');
}

@font-face {
  font-family: 'poppins';
  font-style: normal;
  font-weight: bold;
  src:
    url('../../fonts/poppins-bold.woff2') format('woff2'),
    url('../../fonts/poppins-bold.woff') format('woff');
}

@font-face {
  font-family: 'poppins';
  font-style: normal;
  font-weight: bold;
  src:
    url('../../fonts/poppins-bold.woff2') format('woff2'),
    url('../../fonts/poppins-bold.woff') format('woff');
}

@font-face {
  font-family: 'merriweather';
  font-style: normal;
  font-weight: normal;
  src:
    url('../../fonts/merriweather-regular.woff2') format('woff2')
}

@font-face {
  font-family: 'nimbus';
  font-style: normal;
  font-weight: normal;
  src:
    url('../../fonts/NimbusSanL-Reg.woff2') format('woff2'),
    url('../../fonts/NimbusSanL-Reg.woff') format('woff')
}

@font-face {
  font-family: 'nimbus';
  font-style: normal;
  font-weight: bold;
  src:
    url('../../fonts/NimbusSanL-Bol.woff2') format('woff2'),
    url('../../fonts/NimbusSanL-Bol.woff') format('woff')
}