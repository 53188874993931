.agree-to-reporting-terms {
  display: inline-block;
  margin-top: 10px;

  .reporting-terms-popup {
    position: fixed;
    background: rgba(0,0,0,0.7);
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 100000;
    justify-content: center;
    align-items: center;
    display: none;

    &.show {
      display: flex;
    }

    .reporting-terms-popup-content {
      height: 600px; 
      width: 600px;
      background: white;
      border-radius: 12px;

      .reporting-terms-popup-buttons {
        display: inline;
        margin: 0px 0px 25px 0px;
        width: auto;
        float: right;

        button {
          border: none;
          
          &.cancel {
            background: none;
            color: black;
            margin: 0;
            width: auto;
          }
          &.agree {
            background: #568BB1;
          }

          p {
            margin: 0;
          }
        }
      }

      .reporting-terms-popup-wrapper {
        margin: 50px 35px 50px 50px;
        height: 500px;
        overflow: scroll;
        padding-right: 15px;

        h1 {
          text-align: center;
          font-size: 20px;
          width: 100%;
          margin: 0px 0px 35px 0px;
        }

        ul {
          padding: 0px;

          li {
            margin-bottom: 45px;
            text-align: left;
            height: auto;

            span {
              font-size: 18px;
              margin-right: 30px;
            }

            h3 {
              font-size: 18px;
              display: inline;
              margin-right: 12px;
            }

            p {
              font-size: 16px;
              margin-bottom: 55px;
              display: inline;
              line-height: 36px;

              &.sub_paragraph {
                display: block;
                margin-top: 30px;
              }
            }

            ul {
              li {
                margin-left: 48px;
              }
              li:first-child {
                margin-top: 35px;
              }
              p {
                width: calc(100% - 45px);
                display: block;
              }
            }
          }
        }
      }
    }    
  }

  i {
    &.agreed-to-term {
      color: #6b9c40 !important;
      position: relative !important;
      left: unset !important;
      top: unset !important;
    }
    &.not_agreed-to-term {
      color: red;
    }
  }
  
  .reporting-terms-show-popup-wrapper {
    display: flex;
    align-items: center;
    height: 65px;
 
    .reporting-terms-show-popup-link {
      background: 0;
      color: #0000FF;
      margin: 0px 8px 0px 0px;
      padding: 0;
      font-size: 12px;
      width: auto;
      text-decoration: underline;
      border: 0;
    }
  }
}