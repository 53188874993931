.informationals {
    display: flex;
    flex-direction: column;
    color: white;
    align-items: center;
    background-blend-mode: multiply;
    background-image: linear-gradient(180deg, #56bbb1 10%, #2f5d62 91%), url(https://www.cinqdi.com/wp-content/uploads/sites/66/2023/11/coming-soon-BG.jpeg) !important;

    .informationals_wrapper {
      width: 43vw;
      margin-bottom: 80px;
    }
  
    h1 {
      font-size: 38px;
      text-align: center;
      height: 150px;
      line-height: 60px;
      margin: 120px 0px 60px 0px;
      border-bottom: 1px solid white;
    } 

    ul {
      padding: 0px;

      li {
         margin-bottom: 45px;
         text-align: left;

        span {
          font-size: 21px;
          margin-right: 30px;
        }

        h3 {
          font-size: 22px;
          display: inline;
          margin-right: 12px;
        }

        p {
          font-size: 20px;
          margin-bottom: 55px;
          display: inline;
          line-height: 36px;

          &.sub_paragraph {
            display: block;
            margin-top: 30px;
          }
        }

        ul {
          li {
            margin-left: 48px;
          }
          li:first-child {
            margin-top: 35px;
          }
        }
      }
    }

    video {
      max-width: 500px;
    }

    p {
      font-size: 16px;
      margin-bottom: 55px;
    }

    label {
      margin: 12px auto 65px;
      font-size: 14px;
    }
}