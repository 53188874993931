/*  Client Dashboard   
    ________________

    This is the main css class for the ClientDashboard.
    Changes to this file will only reflect within the ClientDashboard.

    Note: The only file in the client_dashboard directory that doesn't
    have a self-contained css wrapper class is widgets.scss. These 
    still needs to be refactored.

    *  Please add changes within the .client_dashboard {}.

    ________________
*/

.fixed-item {
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  background-color: white;
  z-index: 100000;
  height: 100px;
  padding-top: 45px;
}

.client_dashboard {

  // This is the main header of the ui
  .client_dashboard_header {
    position: relative;
    width: 100vw;
    height: 64px;
    background-color: #222222;
    color: white;
    padding-left: 50px;
    padding: 0px 50px;
    align-items: center;
    display: flex;
    box-sizing: border-box;

    .client_dashboard_header_wrapper {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;

      .header_logo_interest_state_wrapper {
        display: flex;
        align-items: center;

        img {
          height: 29px;
        }

        .interest-states {
          display: flex;
          align-items: center;
          margin-left: 18px;

          p {
            margin: 5px 0px 0px 7px;
            font-size: 16px;
          }

          .fa-location-dot {
            color: #08beb1;
            font-size: 26px;
          }
        }
      }

      .dashboard-search {
        height: 40px;
        width: 473px;
        background-color: #e8e8e8;
        border: 0;
        font-size: 15px;
        border-radius: 5px;
        display: flex;
        align-items: center;

        .fa-magnifying-glass {
          color: #aaacaa;
          font-size: 20px;
          padding: 0px 20px;
        }

        input {
          background-color: #e8e8e8;
          width: calc(100% - 60px);
          box-sizing: border-box;
          height: 100%;
          padding: 4px 0px 0px 0px;
          border: 0;

          &:focus,
          &:focus-visible {
            border: 0 !important;
          }
        }

        button {
          width: auto;
          border: 0;
          font-size: 12px;
          padding: 13px 20px;

          &:hover {
            background: #08beb1;
          }
        }
      }
    }

    .admin_dashboard_toggle {
      position: fixed;
      top: 25px;
      right: 20px;
      cursor: pointer;

      &:hover {
        color: #b7b7b7;
      }
    }
  }

  // This is the main dashboard view of the ui. It can contain an additional header, left sidebar,
  // and dashboard view. 
  .client_dashboard_body {
    display: flex;
    height: calc(100vh - 64px);
    width: 100vw;
    justify-content: space-between;

    .client_dashboard_menu {
      height: 100%;
      width: 80px;
      background-color: #f6f6f6;
      border-right: 2px solid #d392ba;
      overflow: hidden;
      transition: width .5s ease-in-out;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      box-sizing: border-box;

      .client_dashboard_menu_page_navs {
        // todo: This is for the top of the client_dashboard_menu
      }

      .client_dashboard_menu_page_links {

        // This is the bottom of the client_dashboard_menu
        ul {
          bottom: 0;
          padding: 0;
          position: fixed;
          width: 80px;

          li {
            font-size: 18px;
            padding: 6px 0;
            text-align: center;
            width: 100%;

            i {
              &:hover {
                cursor: pointer;
              }
            }
          }
        }
      }

      .client-menu-option {
        font-size: 13px;
        height: 80px;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        &:hover {
          cursor: pointer;
          background-color: rgba(246, 194, 80, .3);
        }

        i {
          color: #3a3b25;
          font-size: 18px;
          margin: auto;
          padding-left: 0;
          padding-top: 20px;
          text-align: center;
          width: 100%;
        }

        p {
          color: #444645;
          cursor: pointer;
          margin-top: 2px;
          text-align: center;
        }

        &.selected {
          background-color: #f6c250;
        }
      }
    }

    // This is the dashboard view - view width minus main sidebar menu 
    .client_dashboard_view {
      width: calc(100vw - 80px);
      height: 100%;

      .client_dashboard_view_header {
        background-color: #e5e5e5;
        border-bottom: 0.2px solid #48494b;
        height: 236px;
        width: 100%;
        display: flex;
        flex-direction: column;

        &.standard {
          height: 64px;
        }

        &.account-password {
          justify-content: space-between;
          flex-direction: row;
        }

        .client_dashboard_view_header_wrapper {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding-right: 30px;

          p {
            color: #333;
            display: inline;
            font-size: 12px;
            margin-left: 15px;
            margin-right: 15px;
          }

          button {
            border: none;
            margin-left: 20px;
            padding: 8px;
            width: auto;

            &:hover {
              background: #08beb1;
            }

            p {
              color: white;
            }
          }
        }

        h3 {
          height: 64px;
          color: black;
          margin: 0;
          align-items: center;
          display: flex;
          padding-left: 30px;
          min-height: 64px;
        }

        .dash_stats_wrapper {
          width: 100%;
          height: 127px;
          display: flex;
          align-items: center;
          padding-left: 55px;
          box-sizing: border-box;

          .dash_stat {
            background-color: #fff;
            border: 0.5px solid #d3d3d3;
            border-radius: 10px;
            box-shadow: inset 0 0 2px #f6c250;
            float: left;
            height: 111px;
            margin: 0 15px;
            text-align: center;
            width: 150px;

            h1 {
              color: #222;
              font-size: 45px;
              margin: 19px 0 0;
            }

            h2 {
              font-size: 14px;
              margin: 10px 0;
              color: #1c1c1c;
              font-weight: 100;
            }

            .fa-location-dot {
              color: #08beb1;
              font-size: 35px;
              margin-bottom: 17px;
              margin-top: 25px;
            }
          }
        }

        .client_dashboard_header_menu {
          width: 100%;
          display: flex;
          flex-direction: row;

          .client_dashboard_header_menu_tab {
            width: 322px;
            display: flex;
            justify-content: center;
            padding-top: 15px;

            &:hover {
              cursor: pointer;

              h3 {
                color: #08beb1;
              }
            }

            h3 {
              font-size: 16px;
              font-weight: 700;
              margin-bottom: 0;
              height: unset;
              min-height: unset;
              padding: 0;
            }

            &.selected {
              border-bottom: 3px solid #08beb1;
              padding-top: 24px;
              ;

              h3 {
                color: #08beb1;
              }
            }

            &.tab_no_title {
              padding: 0;
              height: 61px;
              justify-content: center;
              align-items: center;

              h3 {
                padding: 16px 0px 0px 0px;
                font-size: 20px;

                &.selected {
                  padding: 0;
                }
              }
            }
          }
        }
      }

      .client_dashboard_view_content {
        height: calc(100vh - 128px);
        background: white;
        overflow-y: scroll;
        padding: 80px;
        box-sizing: border-box;

        &.no_menus {
          height: calc(100vh - 64px);
        }

        //    
        &.maptree_dashboard_view_scroll {
          height: calc(100vh - 271px);
        }

        &.dashboard_view_scroll {
          height: calc(100vh - 479px);
        }

        &.standard {
          padding: 20px 0px;
        }

        &.no_padding {
          padding: 0px;
        }

        &.content_menu_wrapper {
          padding: 0;
          display: flex;
          height: calc(100% - 64px);

          &.no_title {
            height: 100%;
          }
        }

        button.menu_right_action_wrapper,
        a.menu_right_action_wrapper {
          display: flex;
          align-items: center;
          position: absolute;
          right: 60px;
          font-size: 11px;
          top: 15px;
          cursor: pointer;
          text-decoration: none;
          color: black;
          font-size: 12px;
          z-index: 1;
          background: none;
          border: none;
          z-index: 1000000;
          justify-content: right;

          p {
            margin: 6px 0px 0px 6px;
            text-transform: uppercase;
            width: auto;
          }

          &.downloader {
            display: none;
          }

          &:hover {
            text-decoration: underline;
          }
        }

        .client_dashboard_view_content_view_with_menu {
          width: calc(100% - 300px);
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          background: white;
          overflow-y: scroll;
          padding: 80px;
          box-sizing: border-box;

          &.shorter_view {
            height: 76%;
          }

          &.no_padding_view {
            padding: 0;
          }

          &.cluster_padding {
            padding: 40px;
          }

          .cluster_display_wrapper {
            width: 100%;
            padding: 0;
            margin: 0;

            ul {
              display: flex;
              padding: 0;
              margin: 0;

              li:first-of-type {
                margin: 0px 10px 0px 0px;
              }

              li:last-of-type {
                margin: 0px 0px 0px 10px;
              }

              li {
                border-right: .5px solid #d3d3d3;
                //margin: 0px 10px;
                margin-bottom: 40px;

                .cluster-display {
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  width: 200px;

                  h4 {
                    font-weight: 700;
                    height: 50px;
                    vertical-align: bottom;
                    text-align: center;
                  }

                  .cluster-dials {
                    text-align: center;
                    width: 155px;

                    .dials {
                      display: flex;
                      justify-content: center;

                      .gauge {
                        color: #000;
                        float: left;
                        width: 120px;

                        #avgAge {
                          margin: auto;
                        }
                      }
                    }
                  }

                  p.cluster_link {
                    height: 50px;
                    text-align: center;
                    font-size: 12px;
                    color: blue;
                  }

                  .demographics,
                  .social-attitudes,
                  .factors {
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    h3 {
                      font-size: 14px;
                      padding-bottom: 5px;
                    }

                    .cluster-demo {
                      font-size: 12px;
                      font-weight: 700;
                      margin: 20px 0px 12px;
                      text-align: center;
                      width: 100%;
                    }

                    .cluster-bar-view {
                      height: auto;
                      margin: 0px 0px 5px 0px;
                      display: flex;
                      flex-direction: column;
                      font-size: 16px;

                      h2 {
                        color: #1c1c1c;
                        font-size: 10px;
                        font-weight: 100;
                        line-height: 28px;
                        margin: 0px 0px 0px 26px;
                        height: 22px;
                      }

                      .cluster-bar-wrapper {
                        display: flex;

                        p {
                          margin: 2px 8px 0px 0px;
                          width: 15px;
                          font-size: 14px;
                        }

                        .cluster-bar {
                          display: flex;
                          height: 20px;
                          width: 125px;
                          align-items: center;
                          background-color: #d3d3d3;

                          .bar-weight {
                            background-color: #2f5d62;
                            height: 20px;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }

          .paginate_bar {
            background-color: #2f5d62;
            text-align: center;
            width: 100%;

            ul {
              padding: 0;
              margin: 0;
              height: 35px;
              display: flex;
              justify-content: center;
              align-items: center;

              li {
                color: #fff;
                display: inline-block;
                padding-bottom: 5px;
                padding-top: 8px;
                width: 35px;

                &:hover {
                  cursor: pointer;
                }

                &.selected {
                  background-color: #f6c250;
                  color: #000;
                }
              }

              li:first-of-type {
                padding-right: 5px;
                width: 75px;
              }

              li:last-of-type {
                padding-left: 5px;
                width: 75px;
              }
            }
          }

          .compare-compare {
            display: flex;

            ul {
              width: 100%;
              padding: 0;
              display: flex;
              flex-direction: column;

              li {
                display: flex;

                .compare_widget_wrapper {
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  width: 50%;
                }

                p.compare-cluster-demo {
                  color: unset;
                  font-size: 16px;
                  margin: 30px 0px;

                  &.loading {
                    font-weight: 100;
                  }
                }

                &.compare_widget_display_wrapper {
                  flex-direction: column;
                  width: 100%;
                  margin: 0px 0px 40px;

                  .compare_item_wrapper {
                    display: flex;

                    .compare_widget_wrapper {
                      display: flex;
                      flex-direction: column;
                      align-items: center;
                      width: 50%;

                      .widget_wrapper_bar {
                        width: 100%;

                        &.crossover {
                          width: 99%;

                          p {
                            &.over-eighty {
                              font-size: 10px;
                              right: 0;
                              top: 20px;
                            }

                            position: absolute;
                            right: -37px;
                            line-height: 10px;
                          }
                        }

                        .cluster-bar-view-compare {
                          .cluster-bar-compare {

                            h5 {
                              font-size: 10px;
                              font-weight: bold;
                              text-align: left;
                              position: relative;
                              top: 0;
                            }

                            .bar-count-wrapper {
                              display: flex;

                              p {
                                margin: 0;
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }

        .client_dashboard_view_content_menu {
          background-color: #fafafa;
          border-right: 0.5px solid #305d62;
          float: left;
          height: 100%;
          margin: 0;
          overflow: hidden;
          width: 300px;
          justify-content: start;
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 30px;
          box-sizing: border-box;
          overflow-y: scroll;

          &.activation {
            padding: 0px;
          }

          &.menu_with_header_body {
            padding: 0;
          }

          .content_menu_header {
            background-color: #305d62;
            display: flex;
            float: left;
            height: 114px;
            width: 100%;
            flex-direction: column;

            h1 {
              color: #fff;
              font-size: 24px;
              line-height: 27px;
              margin: 15px auto 0;
              text-align: center;
              width: 230px;
              font-size: 18px;
            }

            .filter-tabs {
              background-color: #305d62;
              display: flex;
              float: left;
              height: 114px;
              width: 100%;
              align-items: end;
              justify-content: center;

              .tab {
                width: 95px;
                background-color: #fafafa;
                border-top-left-radius: 5px;
                border-top-right-radius: 5px;
                background-color: #fff;
                border-left: 0.5px solid #d3d3d3;
                border-right: 0.5px solid #d3d3d3;
                border-top: 0.5px solid #d3d3d3;
                color: #08beb1;
                font-size: 18px;
                font-weight: 700;
                height: 36px;
                text-align: center;
                display: flex;
                align-items: center;

                &.closed {
                  background-color: #305d62;
                  border: none;
                  color: #fff;
                }

                .filter-toggle {
                  font-size: 12px;
                  width: 100%;
                }
              }
            }
          }

          .content_menu_body {
            display: flex;
            flex-direction: column;
            padding: 0px 20px;
            width: 260px;
            margin: 0px 0px 40px;
            align-items: center;
            box-sizing: border-box;

            &.no_padding {
              padding: 0px;
            }

            p {
              font-size: 17px;
              line-height: 28px;
              margin: 30px 0px;
              overflow-y: auto;
              padding-top: 0;
              width: 100%;
              display: inline;
            }

            button {
              border: 0;
              padding: 12px 36px;
              width: 250px;
              margin-top: 20px;

              &.save_new_audience_button {
                background: #05857B;
              }

              &:hover {
                background: #08beb1;
              }

              p {
                margin: 0;
              }
            }

            .activation {
              text-align: left;
              padding: 0px !important;
              margin: 0px !important;

              .records_wrapper {
                margin-bottom: 0px;
              }

              h1 {
                text-align: left;
                margin-bottom: 10px;
              }

              .records_wrapper {
                margin-top: 5px;
                margin-left: -110px;
              }
            }

            .platform_activations {
              margin: 10px 0px;
              display: flex;
              align-items: center;
              justify-content: center;
              color: #08beb1;

              i {
                margin-right: 10px;
              }

              p {
                margin: 0;
              }
            }

            .cluster-description {

              .fa-info-circle {
                color: #568bb1;
                font-size: 20px;
                padding-right: 10px;
                padding-top: 1px;
              }

              p {
                display: inline;
              }
            }

            .content_menu_body_widget {
              height: auto;
            }
          }

          h3 {
            font-size: 22px;
            line-height: 30px;
            margin: 30px 0;
            text-align: center;
          }

          p {
            font-size: 17px;
            line-height: 28px;
            margin: 7px 0px;
            overflow-y: auto;
            padding-top: 0;
            width: auto;

            &.topic-width-restraint {
              max-width: 190px;
            }     
          }

          button {
            border: 0;
            padding: 12px 36px;
            margin-top: 40px;

            p {
              margin: 0;
            }
          }

          &.compare_menu {

            label {
              text-align: left;
              margin-bottom: 10px;

              &.subtext {
                font-size: 14px;
                margin-bottom: 30px;
              }
            }

            table {
              tr {
                margin: 0px 0px 10px 0px;

                th {
                  color: blue !important;
                  text-decoration: underline;
                }
              }
            }

            .compare_audience_options {
              margin: 0px 0px 55px;

              h3 {
                text-align: left !important;
                font-size: 18px !important;
                margin: 10px 0px 15px !important;
              }

              ul {
                display: flex;
                flex-direction: column;
                border-top: 1px solid #bebebe;
                border-bottom: 1px solid #bebebe;
                padding: 10px;
                margin: 0;
                height: 300px;
                overflow-y: scroll;

                li {
                  margin: 5px 0px;
                  width: fit-content;

                  &:hover {
                    color: #08beb1;
                  }

                  .category-button {
                    border: none;
                    font-size: 14px;
                    border: 0;
                    font-size: 14px;
                    margin: 0;
                    width: fit-content;
                    text-align: left;
                    padding: 0px 5px;
                    box-sizing: border-box;
                    border-radius: 0;
                  }
                }
              }
            }

            .compare_geography_options {
              width: 100%;
              display: flex;
              flex-direction: column;
              margin: 0px 0px 35px;

              h3 {
                text-align: left !important;
                font-size: 18px !important;
                margin: 10px 0px 15px !important;
              }

              p {
                display: contents;
              }
            }

            .compare_demographic_options {
              width: 100%;
              margin: 0px 0px 55px;

              h3 {
                text-align: left !important;
                font-size: 18px !important;
                margin: 30px 0px 15px !important;
              }
            }
          }
        }

        .client_dashboard_view_content_widget {
          max-width: calc(100% - 160px);
          margin-bottom: 80px;

          h2 {
            color: #000;
            font-size: 18px;
            margin: 0px 0px 10px 0px;
          }

          .client_dashboard_view_content_widget_body {
            border: 0.2px solid #787878;
            border-radius: 8px;
            padding: 20px 80px;
            width: 100%;

            .widget_body_input_wrapper {
              display: flex;

              &.submit {
                justify-content: end;
                margin-top: 35px;
              }

              p.settings-information-saved {
                width: fit-content;
                color: #08beb1;
                font-size: 16px;
                margin: 0px 18px 0px 0px;
                display: flex;
                align-items: center;
                padding-top: 4px;          
              }

              .widget_body_item {
                width: 25%;
              }
            }

            h3 {
              display: block;
              font-size: 15px;
              padding: 0;
              width: fit-content;
              margin-bottom: 20px;

              p {
                display: inline;
                margin-left: 8px;
                font-weight: 100;
              }
            }

            input,
            select {
              max-width: 200px;
            }

            button {
              font-size: 15px;
              padding: 8px 0px;
              width: 125px;
              border: 0;
            }

            .widget_body_category_wrapper {
              display: flex;
              flex-wrap: wrap;

              .clickable {
                cursor: pointer;
              }

              .widget_body_category_wrapper_item {
                display: flex;
                align-items: center;
                height: 50px;

                .fa-circle-x {
                  color: #bc403f;
                  cursor: pointer;
                }

                p {
                  margin: 3px 10px 0px 5px;
                }
              }
            }
          }
        }

        h2 {
          color: #1c1c1c;
          font-size: 18px;
          font-weight: 100;
          line-height: 28px;
        }

        h3 {}
      }
    }
  }

  .other-widgets {
    width: 100%;
    display: flex;
    flex-wrap: wrap;

    .mini-half-page {
      width: 50%;
      padding: 0;
      margin: 0px 0px 40px 0px;
    }

    .widget {
      color: dimgray;
      float: left;
      width: 100%;
      padding: 30px;
      box-sizing: border-box;

      .widget-div {}

      .header {
        width: 100%;
        z-index: 100;
        display: flex;
        justify-content: space-between;
        box-sizing: border-box;
        align-items: center;

        p {
          color: black;
          padding-left: 5px;
        }

        .fa-ellipsis {
          color: dimgray;
          cursor: pointer;

          .popup {
            display: none;
          }

          &:hover {

            .popup {
              display: block;
              position: absolute;
              width: 100px;
              height: 14px;
              border: solid 0.5px darkgray;
              background-color: white;
              border-radius: 4px;
              padding: 13px;
              margin-left: -110px;
              font-family: 'poppins';
              font-size: 13px;
            }
          }
        }
      }

      .widgetCsvDownload {
        text-align: right;
        font-family: 'poppins';
        font-size: 17px;
        color: #696969;
        text-decoration: none;
        cursor: pointer;
      }
    }
  }




  .your-platform-activations {

    .your-platform-activations_title_wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 45px;

      .your-platform-activations_title_wrapper_description {

        p {
          width: fit-content;
        }
      }

      button {
        background-color: #bc403f;
        border: 0;
        color: #fff;
        font-size: 18px;
        margin: 0;
        padding: 7px 23px;
        text-transform: capitalize;

        p {
          font-size: 16px;
          margin: 5px 0 0;
        }
      }
    }

    .your_platform_activations_table {
      display: flex;

      table {
        width: 100%;
        border-collapse: collapse;

        thead {
          tr {
            th {
              font-size: 17px;
            }
          }
        }

        tbody {
          tr {
            td {
              padding: 12px;

              a {
                color: #0da8c6;
                text-decoration: none;
              }

              img {
                margin-top: 12px;
                max-height: 40px;
                max-width: 80%;
                object-fit: contain;
              }

              ul {
                padding: 0;
                margin: 35px 0px;
                display: flex;
                flex-direction: column;

                li {
                  float: left;
                  height: 35px;
                  width: 100%;
                  display: flex;
                  align-items: center;
                }
              }
            }
          }
        }
      }
    }
  }

  .export_audience_tile {
    .activate_check_title_wrapper {
      display: flex;
      align-items: center;

      input[type='checkbox'] {
        margin-right: 14px;
        margin-top: 0;
        min-width: 20px;
        width: 20px;
      }

      i {
        font-size: 40px;
      }

      h4 {
        width: auto;
        margin: 8px 14px 0px 6px;
        font-size: 30px;
      }

      p {
        font-size: 13px;
        width: fit-content;
        margin: 8px 0px 0px 0px;

        &.error {
          border: none;
          color: red;
          margin: 8px 15px 0px 20px;
          display: none;
          font-size: 16px;

          &.show {
            display: block;
          }
        }
      }
    }

    .export_audience_body_wrapper {
      display: flex;
      align-items: center;
      margin: 16px 0px 0px 0px;

      .field_value {
        margin-right: 20px;

        .field_value_label {
          display: flex;
          width: auto;
          align-items: center;
          height: 40px;

          p.mandatory {
            color: #bc403f;
            margin: 10px 7px 0px 0px;
            font-size: 18px;
            width: fit-content;
          }

          label {
            width: fit-content;
            font-size: 13px;
            margin: 0;
          }
        }

        .field_value_input {
          display: flex;
          height: 40px;
          align-items: center;

          input[type='radio'] {
            width: 12px;
            min-width: 12px;
            margin: 0;
            margin: 0px 5px 0px 0px;
          }

          p {
            width: auto;
            margin: 5px 15px 0px 0px;
          }
        }
      }
    }

    .export_button_wrapper {
      display: flex;
      justify-content: end;

      button {
        border: 0;
        color: #fff;
        font-size: 18px;
        padding: 10px 24px;
        text-transform: capitalize;
        width: 150px;
        background-color: #bc403f;

        p {
          margin: 5px 0px 0px 0px;
        }
      }
    }
  }

  // This is a rebuild of the secondary sidebar menu
  .secondary_sidebar {
    &.activations_sidebar {

      h1 {
        color: #222222;
        font-size: 22px;
        margin-bottom: 35px;
        text-align: center;
      }

      .activation_sidebar_content_wrapper {
        display: flex;
        flex-direction: column;

        .records_wrapper {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-bottom: 35px;

          h3 {
            margin: 0px 0px 0px 10px;
            font-size: 14px;
          }
        }

        .activation_summary_wrapper {
          p {
            margin: 0px 0px 35px 0px;
          }

          b {
            color: #08beb1;
          }
        }

        ul.activation_details_wrapper {
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 0;
          margin: 0px 0px 35px 0px;

          li {
            display: flex;
            align-items: center;

            b {
              margin-right: 8px;
            }

            p {
              margin: 0;
            }
          }
        }

        .activation_history_wrapper {
          display: flex;
          flex-direction: column;
          align-items: center;

          b {
            margin-bottom: 8px;
          }
        }
      }
    }
  }

  // TREE MAP build 

  .treemap {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    padding: 80px;
  }

  // Refactor zone below
  h3.topic_headlines {
    padding-left: 80px;
  }

  .reset_password_wrapper {
    display: flex;
    align-items: center;
    padding-right: 30px;

    p {
      margin: 4px 10px 0px 0px;
    }
  }

  .widgetCsvDownload {
    // Duplicate from above
    text-align: right;
    font-family: 'poppins';
    font-size: 13px;
    color: #696969;
    text-decoration: none;
    //margin-right: 30px;
  }

  .allWidgetCSVDownloader {
    position: absolute;
    right: 50px;
    top: -30px;

    &.article_downloader {
      right: 0;
    }

    button {
      display: flex;
      justify-content: center;
      align-items: center;
      background: none;
      padding: 0;
      color: black;
      border: 0;
      width: fit-content;

      &:hover {
        text-decoration: underline;

      }

      i {
        font-size: 14px;
      }

      p {
        margin: 0;
        width: fit-content;
        font-size: 12px;
        margin: 9px 0px 0px 6px;
      }
    }
  }

  #treemap {
    // treemap screen sizing
    max-width: 100%;
    width: 100%;
  }

  // warning banner
  .warning-banner {
    background-color: lightyellow;
    padding: 15px 50px;
  }
}

.article-page {
  .client_dashboard_view_content {
    padding: 25px !important;
  }

  .widget-box {
    margin: 5px 5px 5px 5px !important;
  }

  .audience-percent-list {
    margin: 5px 5px 5px 5px !important;
  }
}