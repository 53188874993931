.map-wrap {
    position: relative;
    width: 100%;
    height: calc(100vh - 300px);
    /* calculate height of the screen minus the heading */
}

.map {
    position: absolute;
    width: 100%;
    height: 100%;
}

#usa-map-html {
    #map {
        position: absolute;
        top: 0;
        bottom: 0;
        width: 100%;
    }

    h2,
    h3 {
        margin: 10px;
        font-size: 18px;
    }

    h3 {
        font-size: 16px;
    }

    p {
        margin: 10px;
    }

    .overlay {
        position: absolute;
        bottom: 0;
        margin-left: 20px;
        font-family: Arial, sans-serif;
        border-radius: 3px;

        &.left {
            left: 0;
        }

        &.right {
            right: 0;
        }
    }

    #legend {
        padding: 10px;
        padding-left: 30px;
        background-color: white;
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
        margin-bottom: 2px;
        margin-right: 2px;
    }

    #legend h4 {
        margin: 0 0 10px;
        font-size: 0.8em;
        width: 200px;
        text-align: center;
    }

    #legend div span {
        display: inline-block;
        height: 20px;
        width: 30px;
        margin: 0 -2.5px;
    }

    #legend div.labels {
        margin-left: -20px;
    }

    #legend div label {
        font-size: 0.55em;
        display: block;
        float: left;
        width: 30px;
        text-align: center;
        box-sizing: border-box;
    }

    #legend div label:nth-child(1) {
        padding-left: 10px;
    }

    #info {
        bottom: 0;
        height: 100px;
        width: 250px;
    }
}