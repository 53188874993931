.email-helper {
  bottom: 15px;
  color: #568bb1;
  font-size: 25px;
  position: fixed;
  right: 15px;
  z-index: 30000;
}

.email-helper:hover {
  cursor: pointer
}

#email-help,
.privacy-footer-button {
  position: fixed;
  z-index: 30000;

  &:hover {
    cursor: pointer;
  }
}

.privacy-footer-button {
  bottom: 55px;
  color: #000;
  font-size: 25px;
  right: 15px
}

#feedback-form {
  background-color: #222a68;
  border-top-left-radius: 10px;
  color: #fff;
  height: 100%;
  overflow: hidden;
  position: fixed;
  right: -350px;
  top: 2px;
  transition: right .5s ease-in-out;
  width: 340px;
  z-index: 30001;
}

#feedback-form.feedback-expanded {
  right: 0;
  transition: right .5s ease-in-out
}

#feedback-form .form {
  padding: 45px 30px;
  text-align: center;
  background-color: #232a68;
}

#feedback-form .form p {
  font-size: 18px;
  line-height: 24px
}

#feedback-form .form .text-area {
  border-radius: 5px;
  font-family: Lucida Sans;
  font-size: 14px;
  height: 120px;
  padding: 10px;
  width: 240px
}

#feedback-form .form input {
  margin-left: 10px;
  width: 248px
}

#feedback-form .fa-xmark {
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
}

#feedback-form .fa-circle,
#feedback-form .fa-circle-exclamation,
#feedback-form .fa-star {
  font-size: 25px !important;
  padding: 0 5px
}

#feedback-form #feedback-button {
  background: #fff;
  border-radius: 5px;
  bottom: 50px;
  height: 46px;
  padding: 0 22px;
  position: fixed;
  right: 66px
}

#feedback-form #feedback-button p {
  height: 14px;
  line-height: 18px;
  margin: 0;
}

#feedback-form .form p {
  font-size: 18px;
}

#feedback-form #feedback-button p {
  height: 14px;
  line-height: 18px;
  margin: 0;
  font-size: 14px;
}

#feedback-form .star-comment {
  font-style: italic;
  margin-top: -9px
}

.category-button {
  border: 0.5px solid #a9a9a9;
  border-radius: 12px;
  display: inline-block;
  float: left;
  font-size: 10px;
  margin-right: 15px;
  padding: 6px 25px 4px 15px;
  text-align: center;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;

  &.clickable {
    cursor: pointer;
  }

  .fa-plus {
    margin-right: 5px;
  }
}

// Topic Lists!
.topic-list {
  width: 100%;
  display: flex;
  justify-content: start;
  padding: 28px 70px;
  box-sizing: border-box;
  border-bottom: 1px solid #a9a9a9;


  &.topic_audience_tile {
    padding: 0;
    justify-content: left;
    border: 0;
  }

  .user-topics {

    .topic_title_wrapper {
      display: flex;
      padding-bottom: 10px;
      align-items: center;

      p {
        padding: 5px 12px 0px 0px;
      }

      .fa-pen-to-square {
        font-size: 13px;
        color: #1c1c1c;

        &:hover {
          color: #08beb1;
        }
      }
    }

    p {
      display: inline;
      float: left;
      margin: 0;
      padding-right: 15px;
      padding-top: 2px;
      width: auto;
    }

    .inline-list {
      display: inline-block;
      width: auto;

      .category-button {
        border: 0.5px solid #a9a9a9;
        border-radius: 12px;
        display: inline-block;
        float: left;
        font-size: 10px;
        margin-right: 15px;
        padding: 6px 15px 4px 15px;
        text-align: center;
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
        user-select: none;

        i {
          margin-right: 10px
        }
      }
    }
  }
}


.dashboard-audience-list {
  height: 100%;
  width: 100%;
  background: white;
  overflow-y: scroll;

  ul {
    padding: 0;
    margin: 0;

    li {
      display: flex;
      padding: 20px 80px;
      border-bottom: 0.05px solid #a9a9a9;

      &:hover {
        background-color: rgba(246, 194, 80, 0.125) !important;
      }
    }
  }

  .audience-tile {
    width: 100%;
    display: flex;
    flex-direction: column;

    &:hover {
      //background-color: rgba(246,194,80,.125)!important;
    }

    .audience-tile-title-wrapper {
      display: flex;
      height: 40px;

      i {
        &.selected {
          color: #d392ba;
        }

        &:hover {
          cursor: pointer;
        }
      }
    }

    .audience-tile-description-wrapper {
      height: fit-content;
      margin-bottom: 15px;
    }

    .audience-tile-topics-wrapper {
      height: 40px;
    }

    .audience-tile-count-platform-wrapper {
      display: flex;
      align-items: center;

      .fa-line-chart {
        display: inline;
      }

      p {
        display: inline;
        width: fit-content;
        margin: 3px 0px 0px 0px;
      }

      .platform_activations {
        display: inline;
        color: #08beb1;
        margin-left: 60px;

        i {
          margin: 2px 6px 0px 0px;
        }
      }
    }

    .title-desc {
      margin-left: 11px;
      margin-top: 0;
      color: #787878;
      display: inline;
      font-size: 17px;
      margin: 0;
    }

    p.column-list-option {
      cursor: pointer;
      font-size: 20px;
      height: 38px;
      margin-bottom: 5px;
      margin-top: 0;
      padding-left: 15px;
      position: relative;
      box-sizing: border-box;
    }

    .fa-info-circle {
      display: flex;
      color: #568bb1;
      font-size: 20px;
    }


    .headline-count.audience {
      bottom: -7px;
      display: inline-block;
      position: relative;
      right: -13px;
    }

    .headline-count {
      bottom: -8px;
      color: #48494b;
      float: left;
      font-size: 12px;
      position: absolute;
      right: -150px;
      top: 15px;
    }
  }
}

.headline-tile {
  width: 100%;
  border-bottom: 0.5px solid #787878;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &.widget-screen {
    padding: 10px 5px 20px 5px;
  }

  &:hover {
    cursor: pointer;
    background: rgba(246, 194, 80, .125) !important;
  }
}

.headline {
  display: flex;
  height: auto;
  align-items: center;
  padding: 20px 80px;

  .headline_image_wrapper {
    display: flex;

    img {
      margin-right: 35px;
      width: 150px;
    }


  }

  .headline_info_wrapper {

    p {
      display: inline;
      width: 100%;
      float: left;
      margin: 0px;

      &.title {
        font-size: 18px;
        font-weight: bold;
        line-height: 26px;
      }

      &.date {
        color: #787878;
        font-size: 12px;
        margin-top: 2px;
      }

      &.blurb {
        font-size: 15px;
        margin-top: 2px;
        line-height: 22px;
      }
    }

    .category-button {
      margin-top: 10px;
    }
  }

  &.condensed {
    width: 360px;
    display: inline-block;
    padding: 8px 4px;
    margin: 1px 8px 1px 8px;

    div {
      float: left;
    }

    .title {
      font-weight: bold;
    }

    .date {
      font-size: 12px;
    }
  }
}

.audience-bars {
  margin-top: 10px;

  p {
    font-size: 16px;
    margin: 0px;

    &.percent {
      font-size: 11px;
      margin-top: -4px;
    }
  }
}

.audience-bars:last-child {
  margin-bottom: 100px;
}

.audience-bar {
  height: 20px;
  background-color: lightgray;
  width: 400px;

  p {
    color: black;
    font-size: 14px;
    padding: 4px 5px;
    position: absolute;
    right: 0px;
    width: auto;
  }

  .bar-weight {
    height: 20px;
    background-color: #bc403f;
  }
}

.audience_tile_view {
  display: flex;
  width: calc(100vw - 80px);

  .audience_tile_view_third_wrapper {
    display: flex;
    width: 33.333%;
    flex-direction: column;

    .audience_tile_view_title_wrapper {
      display: flex;
      align-items: center;
      justify-content: center;

      h3 {
        width: auto;
      }

      .fa-info-circle {
        color: #568bb1;
        font-size: 20px;
        padding-left: 10px;
        padding-top: 1px;
        margin-top: 40px;
      }
    }

    h3 {
      height: 70px;
      width: 100%;
      font-size: 21px;
      margin: 0;
      justify-content: center;
      display: flex;
      align-items: center;
      margin-top: 40px;
    }

    ul {
      padding: 0;
      width: 100%;
      margin: 0;

      li {
        display: flex;
        height: 320px;


        .audience-tile {
          width: 100%;
          flex-direction: column;
          border-bottom: 0.05px solid #a9a9a9;
          padding: 40px 80px;
          justify-content: center;

          &:hover {
            background-color: rgba(246, 194, 80, .125) !important;
          }

          .audience-tile-title-wrapper {
            display: flex;
            height: 40px;

            i {
              &.selected {
                color: #d392ba;
              }

              &:hover {
                cursor: pointer;
              }
            }
          }

          .audience-tile-description-wrapper {
            display: flex;
            height: fit-content;
            margin-bottom: 15px;
            min-height: 65px;
            align-items: center;
          }

          .audience-tile-topics-wrapper {
            display: flex;
            align-items: center;
            min-height: 60px;
            margin-bottom: 10px;
          }

          .audience-tile-count-platform-wrapper {
            display: flex;
            align-items: center;

            .fa-line-chart {
              display: inline;
            }

            p {
              display: inline;
              width: fit-content;
              margin: 3px 0px 0px 0px;
              font-size: 15px;
            }

            .platform_activations {
              display: inline;
              color: #08beb1;
              margin-left: 60px;

              i {
                margin: 2px 6px 0px 0px;
              }
            }
          }

          .title-desc {
            margin-left: 11px;
            margin-top: 0;
            color: #787878;
            display: inline;
            font-size: 15px;
            margin: 0;
          }

          p.column-list-option {
            cursor: pointer;
            font-size: 16px;
            height: 38px;
            margin-bottom: 5px;
            margin-top: 0;
            padding-left: 15px;
            position: relative;
            box-sizing: border-box;
          }

          .fa-info-circle {
            display: flex;
            color: #568bb1;
            font-size: 20px;
          }
        }
      }
    }
  }
 
  .audience_tile_view_third_wrapper:first-child {
    border-right: 0.05px solid darkgray;
    width: 33.333%;
  }

  .audience_tile_view_third_wrapper:last-child {
    border-left: 0.05px solid darkgray;
    width: 33.333%;
  }
}


#usa_map_holder.smaller {
  zoom: .9;
  height: 400px;
  margin: auto;
  position: relative;
  width: 790px;

  #usa_map {
    height: 400px;
    position: relative;
    width: 750px;
  }
}

.breakdown-display {
  left: 0;
  margin-left: auto;
  margin-right: auto;
  max-width: 1200px;
  right: 0;
}



.audience-breakdown {
  .audience-state-filter-list-wrapper {
    display: flex;
    align-items: center;
    height: 60px;
    width: 100%;
    border-bottom: 1px solid black;
    padding: 0px;
    overflow-x: scroll;

    .filter-button {
      border-radius: 12px;
      color: #000;
      display: inline-block;
      float: left;
      font-size: 16px;
      margin-top: 10px;
      padding: 5px 25px 5px 15px;
      text-align: center;
      width: -webkit-fit-content;
      width: -moz-fit-content;
      width: fit-content;
    }

    .audience-state-filter-buttons-wrapper {
      display: flex;
      width: auto;
      flex-wrap: nowrap;
      height: 60px;
      align-items: center;

      .filter-button {
        height: auto;
        width: max-content;
        margin: 0;
        padding: 0px 25px;
        user-select: none;
        cursor: auto;

        i {
          cursor: pointer;

          &:hover {
            color: #08beb1;
          }
        }
      }
    }
  }
}

.audience-record-details {
  display: flex;
  height: 40px;
  justify-content: space-between;
  border-bottom: 1px solid black;
  padding: 0px 20px;
  align-items: center;

  h3 {
    margin: 7px 0px 0px 15px;
  }

  .audience-record-wrapper {
    display: flex;
    align-items: center;
  }
}

.cat-topics {
  display: flex;
  align-items: center;
  height: 40px;
  border-bottom: 1px solid black;

  h3 {
    width: fit-content;
    margin: 0 20px 0 17px;
  }

  .category-button {
    i {
      margin-right: 10px;

      &:hover {
        cursor: pointer;
      }
    }
  }
}


.widgets {

  .date-range-wrapper {
    display: flex;
    height: 50px;
    justify-content: end;
    align-items: center;


    .date-range {
      display: flex;
      align-items: center;

      input {
        padding: 0;
      }

      p.range-to {
        margin: 0px 10px;
      }

      button {
        border: none;
        margin: 0px 15px 0px 20px;
        padding: 8px 22px;
        width: auto;
        height: 40px;

        &:hover {
          background: #08beb1;
        }

        p {
          color: #fff;
          display: inline-block;
          font-size: 14px;
          height: 11px;
          line-height: 28px;
          margin: 0;
        }
      }
    }
  }
}


///// Filter Fun Stuff
/// 
/// 
.widget-filter {
  margin-top: 5px;

  &.full-page {
    padding: 4px;
  }

  div {
    margin: 0px;

    &.label {
      padding: 0px 10px;
      width: fit-content;
      color: black;
      font-size: 15px;
      margin-top: -7px;
      margin: 5px 0px;
      display: contents;

      &:hover {
        cursor: pointer;
      }

      &.universe {
        display: none;
        padding: 0px 30px;

        i {
          margin-right: 10px;
        }

        &.show {
          display: block;
        }
      }
    }
  }
}

.filter-toggle {
  font-size: 12px;
  margin-top: -5px;
  width: 100%;

  &:hover {
    cursor: pointer;
  }
}


.filter-header {
  font-size: 14px;
  font-weight: bold;
  float: left;
  margin-left: 10px;

  &:hover {
    cursor: pointer;
  }
}


.widget-filter-list-view {
  margin-top: 20px;
  margin-bottom: 35px;

  &.filter {
    width: 258px;
  }
}

.filter-view {
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 400px;
  color: black;

  float: left;
  padding-left: 7px;
  padding-right: 50px;
  transition: display .5s ease-in-out;
  display: none;
  z-index: 100;

  &.county {
    &.open {
      margin-top: 0px;
    }
  }

  &.open {
    transition: display .5s ease-in-out;
    display: block;
    margin-top: -15px;
  }

  ul {
    list-style: none;
    padding-left: 20px;

    li {
      list-style: none;
      color: white;
    }
  }

  input {
    height: 16px;
    margin: 4px 10px 0px 0px;
    min-width: 20px;
    vertical-align: -3px;
  }

  label {
    width: fit-content;
    color: black;
    font-size: 14px;

    &:hover {
      cursor: pointer;
    }

  }

  .fa-close {
    color: black;
    font-size: 20px;
    bottom: 8;
    left: 0;
    margin-right: 8px;
  }

  p {
    font-size: 16px;
    width: 100%;
    display: inline;
  }

  &.general_election_voter,
  &.likely_presidential_primary_voter,
  &.likely_primary_voter {
    ul {
      padding: 0px 50px;
    }
  }
}

.likely_voter_universe_filter_primary_wrapper {
  display: block;
  padding: 0px 4px;

  i {
    margin: 5px 10px 0px 0px;
    float: left;
  }

  p {
    margin: 0 !important;
    font-weight: 100;
    width: auto !important;
  }
}


.filter-view input {
  height: 16px;
  margin: 4px 10px 0 0;
  min-width: 20px;
  vertical-align: -3px;
}

.filter-header {
  float: left;
  font-size: 14px;
  font-weight: 700;
  margin-left: 10px;
  text-align: left !important;
}

.widget-box-wrapper {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.widget-box {
  width: 27%;
  min-width: 350px;
  border: 1px solid darkgray;
  float: left;
  padding: 20px 20px 20px 20px;
  margin: 20px 0px;
  min-height: 230px;

  .widget {
    padding: 0 !important;
  }
}