$mvd-red: #bc403f;
$dark-grey: #48494B;
$mvd-blue: #0da8c6;
$mvd-blue-opaque: #0da8c610;
$light-grey: #787878;

$mvd-teal: #2F5D62;
$cinq-teal: #2F5D62;
$mvd-mulberry: #864B8D;

$cinq-mulberry: #D392Ba;
$cinq-lemon: #F6C250;
$cinq-green: #305d62;

$cinq-green: #08beb1;

$cinq-light-yellow: #F6C250;

$cinq: #568BB1;

$yellow : #f5d372;
$navyblue : #222a68;
$dark-blue : #222a68;
$blue : #000086;
$pink : #ba4c4b;
$purple : #2e016a;
$maroon : #943203;
$green : #6b9c40;
$light-green : #6b9c4020;
$orange : #f56c0c;
$lightpink : #f59185;

$cinq-yellow-background: #f6C25020;

.grey {
    color: #222222 !important;
}

.light-grey {
    color: $light-grey;
}

.white,
.text-white {
    color: white;
}

.lemon {
    color: $cinq-lemon;
}

.background-red {
    background-color: $maroon;
}

.background-cinq-mulberry {
    background-color: $cinq-mulberry;
}

.background-cinq-lemon {
    background-color: $cinq-lemon;
    color: $dark-grey;
}

.background-cinq-light-yellow {
    background-color: $cinq-light-yellow !important;
}

.background-cinq-light-yellow-see-through {
    background-color: #F6C25020 !important;
}

.background-cinq-blue {
    background-color: #08beb1 !important;
    border-color: #08beb1 !important;
}

.background-cinq-teal {
    background-color: $cinq-teal !important;
}