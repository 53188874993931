.confirm-activation {

  .confirm_activation_title_img_wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;

    h3 {
      width: fit-content;
    }
    
    img {
      max-height: 50px;
    }
  }

  h4 {
    text-decoration: underline; 
  }

  input[type='checkbox'] {
    height: 15px;
    min-width: 0;
  }

  .error {
    border: none;
    color: red;
  }

  label.error {
    border: 0;
    color: red;
    display: none;
    font-size: 15px;
    font-weight: 100;
    margin: 5px 0 10px;

    &.show {
      display: block;
    }
  }

  .under-label {
    color: #a9a9a9;
    font-size: 10px;
    margin-left: 65px;
  }

  .authorized_timestamp_wrapper {
    display: flex;
    max-width: 250px;
    justify-items: center;
    align-items: center;
   
    .under-label {
      color: #a9a9a9;
      font-size: 10px;
      margin-left: 65px;
    }

    .fa-circle-check {
      color: #6b9c40;
      margin-right: 10px;
    }

    .timestamp {
      color: #08beb1;
      font-size: 12px;
    }
  }

  .contact_information_wrapper {
    display: flex;
    margin-bottom: 35px;

    .contact_information_input {
      display: flex;
      flex-direction: column;
      margin-right: 35px;

  
      .contact_information_label_wrapper {
        display: flex;
        height: 25px;
      }

      .contact_information_value_wrapper {
        height: 50px;
      }
    }    
  }

  .confirm_activation_button_wrapper {
    display: flex;

    button {
      height: 38px;
      background-color: #bc403f;
      border: 0;
      color: #fff;
      font-size: 18px;
      margin: 25px 0 0;
      padding: 7px 23px;
      text-transform: capitalize;

      &.cancel {
        margin: 25px 0px 0px 80px;
        background-color: #d3d3d3;
        color: #48494b;
      }

      p {
        font-size: 15px;
        margin: 5px 0px 0px;
      }
    }
  }  
}