.landing {

  .auth-message {
    background-color: #F6C250;
    width: 100%;
    display: flex;
    position: relative;
    height: 25px;
    width: 100vw;
    align-items: center;
    
    p {
      margin-block: 0px;
      font-size: 14px;
      height: 20px;
      line-height: 24px;
    }
  }

  .auth-form {
    display: flex;
    background-blend-mode: multiply;
    background-image: linear-gradient(180deg, #56bbb1 10%, #2f5d62 91%), url(https://www.cinqdi.com/wp-content/uploads/sites/66/2023/11/coming-soon-BG.jpeg) !important;
    padding: 0px;

    flex-direction: column;

    .landing_wrapper {
      width: 301px;
      margin: 132px auto 99px;
      &.invited {
        margin: 52px auto 99px;
      }

      h2 {
        font-size: 36px;
        color: white;
        text-align: center;
        height: 88px;
        margin: 0px 0px 35px 0px;
        border-bottom: 1px solid white;
        &.invited {
          font-size: 30px;
        }
      }

      h4 {
        font-size: 31px;
        color: white;
        margin-bottom: 25px;
      }

      h5 {
        font-size: 20px;
        color: white;
        margin: 15px auto 80px;
      }

      .login_entry_wrapper {
        margin-bottom: 35px;
        
        input {
          width: 301px;
          height: 42px;
          box-sizing: border-box;
          padding: 4px 2px 0px 10px;
          border: 0 !important;
        }

        label {
          color: #fff;
          margin-top: 20px;
          text-align: left;
          width: 100%;

          &.error {
            border: 0;
            color: #f6c250;
            display: none;
            font-size: 15px;
            margin: 5px 0 0;

            &.show {
              display: block;
            }
          }
        }
      }
      .submit_wrapper {
        border-top: 1px solid #ffffff;

        button {
          margin-top: 40px;
          font-weight: bold;
          box-shadow: none;
          border: none;
          width: auto;
          height: 46px;
          font-size: 14px;
          border-radius: 5px;
          padding: 0px 22px;
          border-radius: 5px;
   
          p {
            margin: 0;
            height: 12px;
          }     
        }

        .auth-feedback {
          position: absolute;
          color: #F6C250;
          text-align: center;
          width: 100%;
          margin-top: 21px;

          p {
            color: #864B8D;
            background-color: white;
            width: 230px;
            border-radius: 10px;
            padding: 8px;
            left: 0;
            right: 0;
            margin-top: 20px;
            margin: auto;
            font-weight: bold;
           font-size: 15px;
          }
        }
      }

      .forgot-div {
        margin-top: 102px;   

        p {
          font-size: 14px;
          color: white;
          width: 100%;

          &:hover {
            cursor: pointer;
            color: #F6C250;
          }
        }
      }
    }
  }
}