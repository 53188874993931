.ai {
  position: relative;
  width: calc(100vw - 80px);
  height: 100%;
  left: 0;
  object-fit: cover;
  background-image: none;

  .ai_title {
    width: 100%;
    height: 59px;
    display: flex;

    background-color: #2F5D62;
    //background-color: #3625a1;

    .back_button {
      position: fixed;
      top: 23px;
      left: 35px;
      color: white;
      height: auto;
      width: auto;
      text-transform: capitalize;
      font-size: 15px;
      padding: 0;
      background: unset;
      border: 0;
    }

    .ai_admin_button {
      position: fixed;
      top: 23px;
      right: 35px;
      color: white;
      height: auto;
      width: auto;
      text-transform: capitalize;
      font-size: 15px;
      padding: 0;
      background: unset;
      border: 0;
    }

    .ai_title_wrapper {
      display: flex;
      margin: auto;

      .ai_title_icon {
        background-image: url(../../../images/message_icon.png);
        height: 26px;
        width: 26px;
        color: white;
        float: left;
        align-self: center;
        background-size: cover;
        margin-top: 4px;
      }

      .ai_title_h1 {
        font-size: 30px;
        height: 28px;
        color: white;
        float: left;
        align-self: center;
        margin-left: 17px;
        margin: 0px 0px 0px 14px;
      }
    }
  }

  .ai_chatbox_widget {
    display: block;
    float: right;
    width: 650px;

    /* With Admin Nav showing */
    //height: calc(100% - 224px);
    height: calc(100vh - 224px);
    background: white;

    .ai_chatbox {
      float: left;
      margin: 18px;
      //height: calc(100% + 64px);
      height: calc(100% - 36px);
      /* width full screen */
      width: calc(100% - 36px);
      overflow-y: overlay;

      &.show_graphical {
        /* This is split width with images*/
        width: calc(100% - 318px);
        margin-right: 0;
      }

      .ai_chatbox_ul {
        list-style: none;
        padding-left: 60px;
        margin-top: 53px;

        .ai_chatbox_li {
          display: flex;
          margin-bottom: 35px;
          width: calc(100% - 104px);

          &.ai_ask_li_wrapper {
            display: flex;
            width: 100%;
            justify-content: flex-end;
          }
        }

        .ai_chatbox_icon_wrapper {
          display: flex;
          height: 55px;
          width: 55px;
          background-color: #2F5D62;
          //background-color: #3625a1;
          border-radius: 17px;
          justify-content: center;
          align-self: flex-end;
          margin: 0px 13px 0px 0px;

          .ai_chatbox_icon {
            background-image: url(../../../images/message_icon.png);
            display: flex;
            height: 22px;
            width: 22px;
            color: white;
            float: left;
            align-self: center;
            background-size: cover;
            margin-top: 4px;
          }
        }

        .ai_ask_p {
          color: #666b69;
          background: #e6e4e5;
          border-radius: 12px;
          margin: 0px 0px 0px 13px;
          font-size: 25px;
          padding: 20px 25px 14px 31px;
          text-wrap: wrap;
          max-width: 369px;
          line-height: 35px;
          box-sizing: border-box;
          width: auto;

          &.ai_ask_p_user {
            display: flex;
            //background-color: #3625a1;
            background-color: #2F5D62;
            color: white;
            /*margin-left: 43px; */
            margin: 0px 60px 0px;
          }
        }

        a {
          width: auto;
          display: flex;
          margin: 16px 0px 10px;
          color: #2F5D62;
          text-decoration: underline;
        }

        .ai_chatbox_icon_wrapper_user {
          visibility: hidden;
        }
      }
    }
  }

  // Ai Chat Terminal
  .ai_terminal {
    display: flex;
    height: 101px;
    width: 100%;
    border-top: 2px rgb(210, 204, 206) solid;
    box-sizing: border-box;

    .ai_terminal_input_field {
      display: block;
      height: 100%;
      width: calc(100% - 145px);
      color: rgb(93, 99, 97);
      float: left;
      border: 0;
      font-size: 25px;
      padding: 5px 73px 0px 76px;
      box-sizing: border-box;
      /* This makes it identical margin: -12px 0px 0px 0px; */

      &.input_error {
        &::placeholder {
          color: red;
        }
      }
      &.lockUser {
        cursor: not-allowed;
      }
    }

    .ai_terminal_input_field:focus {
      outline: none;
      border: none !important
    }

    .ai_terminal_input_field_bar_wrapper {
      position: relative;
      height: 100%;
      width: 2px;
      float: left;

      .ai_terminal_input_field_bar {
        height: 40px;
        margin: 0;
        position: absolute;
        top: calc(50% - 20px);
        width: 2px;
        background: #363636;
      }
    }

    .ai_terminal_submit_button {
      display: block;
      height: 100%;
      width: 107px;
      float: left;
      border-left: 1px black;
      background: white;
      border: 0;
      box-sizing: border-box;

      &.lockUser {
        cursor: not-allowed;
      }

      .ai_terminal_submit_icon {
        background-image: url(../../../images/send.png);
        background-size: cover;
        width: 25px;
        height: 25px;
        font-size: 25px;
        margin: auto;
      }
    }

    .ai_terminal_form {
      width: 100%;
    }
  }

  .ai_graphical {
    display: flex;
    height: 100%;
    flex-wrap: wrap;
    width: 236px;
    float: left;
    padding: 18px;
    justify-content: center;
    align-items: center;
    background: white;
    box-sizing: border-box;

    .ai_graphical_h2 {
      margin-top: 118px;
      font-size: 26px;
      color: #463aa8;
      height: 0;
      margin-bottom: 100px;
    }

    .ai_graphical_image {
      max-height: 300px;
      max-width: 225px;
      text-align: center;
      object-fit: cover;
    }

    .ai_graphical_image_button {
     height: 280px;
      text-align: center;
      object-fit: cover;
      background: #e6e4e5;
      color: #666b69;
      border-radius: 28px; 
      border: 1px solid #000000;
      
      &:hover {
        background: #2F5D62;
        color: #ffffff;
        border: 0; 
      }

      p {
        margin: 30px 0px;
        font-size: 16px;
        line-height: 22px;
        text-transform: capitalize;
      }

      i {
        font-size: 28px;
      }
    }
  }

  .ai_ask {
    display: block;
    float: left;
    width: calc(100% - 650px);
    height: calc(100vh - 123px);
    //height: 100%;
    padding: 18px;
    border-right: 2px solid #606060;
    padding: 35px 37px 0px 37px;
    box-sizing: border-box;
    background: white;

    .ai_ask_h2 {
      width: fit-content;
      font-size: 26px;
      //color: #b7aeb1;
      color: #2F5D62;
      margin: auto;
      padding: 0px 0px 15px;
      border-bottom: 1px solid #2F5D62;
    }

    .ai_ask_ul {
      margin: 20px 10px 0px 0px;
      height: calc(100vh - 270px);
      overflow-y: auto;
      padding: 0px 8px 0px 0px;

      .ai_ask_li {
        list-style: none;
        padding: 0;
        font-size: 25px;
        line-height: 39px;
        margin-bottom: 18px;

        .ai_ask_button {
          background: none;
          border: none;
          padding: 0;
          text-align: left;
          color: black;
          font-size: 16px;
          width: auto;
          line-height: 32px;
          font-weight: 300;
          text-transform: capitalize;

          &:hover {
            color: #2F5D62;
          }

          &.lockUser {
            cursor: not-allowed;
          }
        }
      }
    }
  }


  .ai_admin {
    display: flex;
    justify-content: center;

    .ai_admin_message {
      position: fixed;
      top: 75px;
      left: 35px;
      color: #000000;
      font-size: 15px;
      padding: 0;
      background: unset;
      border: 0;
    }

    .ai_admin_wrapper {
      width: 500px;

      .back_button {
        position: fixed;
        top: 23px;
        left: 35px;
        color: white;
        height: auto;
        width: auto;
        text-transform: capitalize;
        font-size: 15px;
        padding: 0;
        background: unset;
        border: 0;
      }

      .add_entry_button {
        position: fixed;
        top: 23px;
        right: 35px;
        color: white;
        height: auto;
        width: auto;
        text-transform: capitalize;
        font-size: 15px;
        padding: 0;
        background: unset;
        border: 0;
      }

      h2 {
        margin: 60px auto 28px;
        text-align: center;
        font-size: 32px;
      }

      h3 {
        text-align: center;
        display: inline-block;
        width: 100%;
      }

      ul {
        max-height: calc(100vh - 300px);
        overflow-y: auto;

        li {
          padding: 15px 0px;
          font-size: 20px;
          border-bottom: 1px solid #2F5D62;
          cursor: pointer;
        }
      }

      .submit_wrapper {
        width: 100%;

        input {
          width: 100%;
          margin-bottom: 20px;
        }

        .img_button_wrapper {
          display: flex;
          align-items: center;
          flex-direction: column;

          img {
            max-width: 350px;
            margin-bottom: 30px;
          }

          button {

            &.update,
            &.add {
              background: #568BB1;
              border: none;
              font-size: 16px;
              text-transform: capitalize;
              margin-bottom: 20px;
            }

            &.delete {
              background: none;
              color: red;
              border: none;
              padding: 0;
              width: auto;
              font-size: 13px;
              text-transform: capitalize;
            }
          }
        }
      }
    }
  }

  .blinking-cursor {
    margin-left: 5px;
    animation: blink 1s infinite;
  }

  @keyframes blink {

    0%,
    50% {
      opacity: 1;
    }

    50.1%,
    100% {
      opacity: 0;
    }
  }
}
