/*  Client Dashboard Sandbox   
    ________________________

    Use this stylesheet if there are any questions regarding
    location in client_dashboard.scss. Changes to this file 
    will only reflect within the ClientDashboard.

    Note: Due to the layering of the classes, you may need to 
    add an '!important' for new changes to take priority.

    * Please add changes within the .client_dashboard {}.

    ________________________
*/


.client_dashboard {


// old file: before-css-merge.scss 
.headline-list-trending {
    height: calc(100vh - 180px);
    overflow-y: scroll;
}

.cluster-bar-view-compare {
    height: 40px;
    text-align: center;
    color: #48494B;
    font-size: 14px;

    h5 {
        font-size: 10px;
        position: absolute;
        top: -30px;
        font-weight: bold;
        margin-bottom: 10px;
    }
}

.cluster-bar-compare {
    height: 20px;
    text-align: center;
    color: #48494B;
    font-size: 14px;

    p {
        z-index: 1000;
        color: black;
        font-size: 14px;
        padding: 4px 5px;
        width: auto;
    }
}

.bar-weight-compare {
    height: 20px;
    background-color: #2F5D62;
    text-align: center;
    color: #48494B;
    font-size: 14px;
}

.compare-compare {
    th {
        font-size: 16px;
        color: #bc403f;
        text-align: center;
    }
}

.compare-cluster-demo {
    margin: 0px;
    width: 100%;
    text-align: center;
    font-size: 12px;
    font-weight: bold;
    margin-bottom: 21px;
}

.activate-form-compare {
    float: left;
}

.widget-screen-compare {
    float: none;
    margin-top: 20px;
    padding-left: 20px;
    width: 90vw;

    #download-csv-link {
        margin-right: 15px;
    }

    .inline {
        display: inline;
    }
}

.left-screen-compare {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    padding-right: 17px;
    overflow-y: scroll;
    overflow-x: hidden;
    box-sizing: content-box;
}

/* possible temp hover solutions */
.client_dashboard_header_menu_tab {
    h3:hover {
        cursor: pointer;
    }
}

.client_dashboard_body .client_dashboard_view .client_dashboard_view_content .client_dashboard_view_content_view_with_menu .cluster_display_wrapper ul li .cluster-display p.cluster_link {
    &:hover {
        cursor: pointer;
    }
}

.side-tab {
    h3:hover {
        cursor: pointer;
    }
}

.category-button {
    &:hover {
        cursor: pointer;
    }

    &.no-hover {
        &:hover {
            cursor: default;
        }
    }
}


// This is a very effective wireframe tool for finding off screen overflows

/*

* {
  outline: 1px solid #f00 !important;
  opacity: 1 !important;
  visibility: visible !important;
}

*/

}